import { configureStore, combineReducers } from "@reduxjs/toolkit";
import USER_REDUCER from "./user/index";

const reducer = combineReducers({
  USER_DATA: USER_REDUCER,
});

const store = configureStore({
  reducer,
});

export default store;
