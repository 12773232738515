import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  tag: {
    selectedTags: [],
    tags: [],
  },
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_LOADING: (state, action) => {
      state.loading = action.payload;
    },
    SET_TAGS: (state, action) => {
      state.tag.tags = action.payload;
    },
    SET_SELECTED_TAGS: (state, action) => {
      state.tag.selectedTags = action.payload;
    },
  },
});

export const { SET_LOADING, SET_TAGS, SET_SELECTED_TAGS } = UserSlice.actions;

export default UserSlice.reducer;

// USAGE:
//   const dispatch = useDispatch();
// dispatch(SET_SELECTED_TAGS(data));
// dispatch(SET_TAGS(data));
// dispatch(SET_LOADING(true||false));

// const selectedTags = useSelector((state) => state.USER_DATA.tag.selectedTags);
// const tags = useSelector((state) => state.USER_DATA.tag.tags);
// const loading = useSelector((state) => state.USER_DATA.loading);
