import { ReactComponent as DOC } from "../assets/attachments/DOC.svg";
import { ReactComponent as EPS } from "../assets/attachments/EPS.svg";
import { ReactComponent as FILE } from "../assets/attachments/FILE.svg";
import { ReactComponent as MOV } from "../assets/attachments/MOV.svg";
import { ReactComponent as MP4 } from "../assets/attachments/MP4.svg";
import { ReactComponent as PDF } from "../assets/attachments/PDF.svg";
import { ReactComponent as PPT } from "../assets/attachments/PPT.svg";
import { ReactComponent as SVG } from "../assets/attachments/SVG.svg";
import { ReactComponent as TXT } from "../assets/attachments/TXT.svg";
import { ReactComponent as XLS } from "../assets/attachments/XLS.svg";
import { ReactComponent as ZIP } from "../assets/attachments/ZIP.svg";

export const renderFileIcon = (extension) => {
  switch (extension.toLowerCase()) {
    case "doc":
    case "docx":
      return <DOC className="attachment-icon" />;
    case "eps":
      return <EPS className="attachment-icon" />;
    case "mov":
      return <MOV className="attachment-icon" />;
    case "mp4":
      return <MP4 className="attachment-icon" />;
    case "pdf":
      return <PDF className="attachment-icon" />;
    case "ppt":
    case "pptx":
      return <PPT className="attachment-icon" />;
    case "svg":
      return <SVG className="attachment-icon" />;
    case "txt":
      return <TXT className="attachment-icon" />;
    case "xls":
    case "xlsx":
      return <XLS className="attachment-icon" />;
    case "zip":
      return <ZIP className="attachment-icon" />;
    default:
      return <FILE className="attachment-icon" />;
  }
};
