import { lazy, Suspense, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { AuthContext } from "../context/AuthContext";
import ViewPublicNote from "../pages/ViewPublicNote";
import ViewPublicTodo from "../pages/ViewPublicTodo";
import Loading from "../assets/images/Loading.gif";

const Home = lazy(() => import("../pages/LandingPage"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const EditNote = lazy(() => import("../pages/EditNote"));
const AddNote = lazy(() => import("../pages/AddNote"));
// const EditTodo = lazy(() => import("../pages/EditTodo"));
const AddTodo = lazy(() => import("../pages/AddTodo"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));

// const token = localStorage.getItem("jwtToken")
//   ? localStorage.getItem("jwtToken")
//   : token;

const authRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/view/note/:id",
    element: <ViewPublicNote />,
  },
  {
    path: "/view/todo/:id",
    element: <ViewPublicTodo />,
  },
];

const pageRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/add-note",
    element: <AddNote />,
  },
  {
    path: "/note/:id",
    element: <EditNote />,
  },
  {
    path: "/add-todo",
    element: <AddTodo />,
  },
  {
    path: "/todo/:id",
    element: <AddTodo isEditMode={true} />,
    // element: <EditTodo />,
  },
];

const Routers = () => {
  const { token } = useContext(AuthContext);
  return (
    <Routes>
      {authRoutes.map((auth, index) => (
        <Route
          key={index}
          exact
          path={auth?.path}
          element={
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <img src={Loading} alt="loading..." />
                </div>
              }
            >
              {auth?.element}
            </Suspense>
          }
        />
      ))}

      <Route element={<ProtectedRoute user={token} />}>
        {pageRoutes.map((page, index) => (
          <Route
            key={index}
            exact
            path={page?.path}
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <img src={Loading} alt="loading..." />
                  </div>
                }
              >
                {page?.element}
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
    </Routes>
  );
};

export default Routers;
