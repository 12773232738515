import React, { useEffect, useState } from "react";
import {  IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const imgStyle = {
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
};

function PreviewImages({ fileList, currentIndex = 0, isConvert }) {
  
  const [activeIndex, setActiveIndex] = useState(currentIndex);

  const handleNext = () => {
    if (activeIndex < fileList.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  useEffect(() => {
    setActiveIndex(currentIndex);
  }, [currentIndex]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <IconButton
        onClick={handlePrev}
        disabled={activeIndex === 0}
        aria-label="previous"
        style={{
          color: activeIndex > 0 ? "black" : "rgba(0, 0, 0, 0.3)", // Disabled state color
        }}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <div
        style={{
          ...imgStyle,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          height: "100%",
        }}
      >
        <img
          src={fileList[activeIndex]?.url}
          alt="preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "10px",
          }}
        />
      </div>

      <IconButton
        onClick={handleNext}
        disabled={activeIndex === fileList.length - 1}
        aria-label="forward"
        style={{
          color: activeIndex < fileList.length - 1 ? "black" : "rgba(0, 0, 0, 0.3)", // Disabled state color
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
}

export default PreviewImages;
