// extendTheme.js
const lightColorScheme = {
  typography: {
    fontFamily: "SF PRO DISPLAY",
    h1: {
      color: "#FF6600",
    },
    h7: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFF",
        },
      },
    },
  },
  palette: {
    mode: "light",
    white: {
      main: "#FFF",
      light: "#FFF",
      dark: "#FFF",
    },
    primary: {
      main: "#6AB7FD",
    },
    secondary: {
      main: "#505C7B",
    },
    textPrimary: {
      main: "#6699FF",
    },
    textSecondary: {
      main: "#00B6D5",
    },
  },
};

const darkColorScheme = {
  typography: {
    fontFamily: "SF PRO DISPLAY",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    h1: {
      color: "#FF6600",
    },
    h2: {
      fontSize: "1.5rem",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#FF6600",
    },
    secondary: {
      main: "#000099",
    },
    textPrimary: {
      main: "#6699FF",
    },
    textSecondary: {
      main: "#00B6D5",
    },
  },
};

export { lightColorScheme, darkColorScheme };
